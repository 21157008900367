import { GatewayManager, GatewayManagerEvents, GatewayMessage } from "./internal/gateway";
import { EventEmitter } from "events";
import { TypedEventEmitter } from "../types/typedEmitter";
import { QuebicOpcodes, QuebicUrls } from "../base";
import { User } from "./controllers/user";
import { Controller } from "./controllers/controller";
import { Space } from "./controllers/space";
import { Channel } from "./controllers/channel";
import { Message } from "./controllers/message";
import { Pins } from "./controllers/pins";
import { Invite } from "./controllers/invite";
import { Media } from "./controllers/media";
import { Gif } from "./controllers/gif";
import { Cdn } from "./controllers/cdn";
import { Dm } from "./controllers/dm";
import { QuebicToken } from "../types/user";
import { QuebicError } from "../types/error";
import { Json2 } from "./internal/json";
import { Heartbeat } from "./message/heartbeat";
import { PushEvent, PushEventClass } from "./events/pushEvent";
import { MemberListEvent } from "./events/memberListEvent";
import { SearchMemberListEvent } from "./events/searchMemberListEvent";

/**
 * Events that are available to listen to on the Quebic Client.
 *
 * @export
 * @enum {number}
 */
export enum ClientEvents {
	/**
	 *	You're ready to use the Quebic client. Whoop whoop!
	 */
	Ready = "ready",
	/**
	 *	Connected to the Quebic Gateway but we're waiting on a welcome message.
	 */
	Connected = "connected",
	/**
	 *	Connecting to the Quebic Gateway as we speak!
	 */
	Connecting = "connecting",
	/**
	 *	Disconnected from the Quebic Gateway.
	 */
	Disconnected = "disconnected",
	/**
	 *	Something important happened you should know about (Here it is!)
	 *	This could be a change in a space, channel, a new message, or anything of the sort.
	 */
	PushEvent = "push_event",
	/**
	 * An update to the member list has been pushed to the client.
	 * It contains a list of different commands that modify your list and should be processed.
	 */
	MemberListUpdate = "member_list_update",
	/**
	 * Search results for a call to space.searchMemberList() are ready.
	 */
	SearchMemberList = "search_member_list",
	/**
	 * The Quebic Gateway has indicated that you must reconnect to continue.
	 * (Your client won't work properly without doing so)
	 */
	ReconnectRequired = "reconnect_required",
	/**
	 *	An error occured. :(
	 */
	Error = "error",
}

/**
 * What Quebic Client environment to run calls against.
 *
 * @export
 * @enum {number}
 */
export enum ClientMode {
	/**
	 *	Quebic production environment (Default)
	 */
	Production,
	/**
	 *	Quebic development environment (You need special access to use this)
	 */
	Development,
	/**
	 * Quebic local environment (You have to work at Quebic to use this)
	 */
	Local,
}

/**
 * An interface that describes the available client events for the emitter.
 */
interface ClientEvent {
	[ClientEvents.Ready]: () => void,
	[ClientEvents.Connected]: () => void,
	[ClientEvents.Connecting]: () => void,
	[ClientEvents.Disconnected]: () => void,
	[ClientEvents.PushEvent]: (event: PushEvent) => void,
	[ClientEvents.MemberListUpdate]: (event: MemberListEvent) => void,
	[ClientEvents.SearchMemberList]: (event: SearchMemberListEvent) => void,
	[ClientEvents.ReconnectRequired]: () => void,
	[ClientEvents.Error]: (error: Error) => void,
}

/**
 * The Quebic Client, this will help you manage all Quebic services (chat, voice, video)
 *
 * @export
 * @class Client
 * @extends {EventEmitter}
 */
export class Client extends (EventEmitter as new () => TypedEventEmitter<ClientEvent>) {
	private gateway: GatewayManager | null;
	private token: string | null;

	private readonly endpoints: string[] = [
		QuebicUrls.ApiUrl_Production,
		QuebicUrls.GatewayUrl_Production,
		QuebicUrls.MediaUrl_Production,
		QuebicUrls.CdnUrl_Production
	];

	private opcodeCallbacks: ((msg: GatewayMessage<any>) => void)[] = [
		this.opcodeHello,
		this.opcodePushEvent,
		this.opcodeHeartbeat,
		this.opcodeReconnect,
		this.opcodeMemberList,
		this.opcodeSearchMemberList,
		this.opcodeCount
	];

	private controllers: Controller[] = [
		new User(this),
		new Space(this),
		new Channel(this),
		new Message(this),
		new Pins(this),
		new Invite(this),
		new Gif(this),
		new Media(this),
		new Cdn(this),
		new Dm(this),
	];

	/**
	 * Creates an instance of the Quebic Client.
	 * @param {ClientMode} [mode=ClientMode.Production]
	 * @memberof Client
	 */
	constructor(mode: ClientMode = ClientMode.Production) {
		super();

		switch (mode) {
			case ClientMode.Development:
				this.endpoints = [QuebicUrls.ApiUrl_Development, QuebicUrls.GatewayUrl_Development, QuebicUrls.MediaUrl_Development, QuebicUrls.CdnUrl_Development];
				break;
			case ClientMode.Local:
				this.endpoints = [QuebicUrls.ApiUrl_Local, QuebicUrls.GatewayUrl_Local, QuebicUrls.MediaUrl_Local, QuebicUrls.CdnUrl_Local];
				break;
		}

		this.token = null;
		this.gateway = null;
	}

	/**
	 * Methods for working with the User object in Quebic.
	 * (This includes authentication)
	 *
	 * @readonly
	 * @type {User}
	 * @memberof Client
	 */
	public get user(): User {
		return this.controllers[0] as User;
	}

	/**
	 * Methods for working with the Space object in Quebic.
	 *
	 * @readonly
	 * @type {Space}
	 * @memberof Client
	 */
	public get space(): Space {
		return this.controllers[1] as Space;
	}

	/**
	 * Methods for working with the Channel object in Quebic.
	 *
	 * @readonly
	 * @type {Channel}
	 * @memberof Client
	 */
	public get channel(): Channel {
		return this.controllers[2] as Channel;
	}

	/**
	 * Methods for working with the Message object in Quebic.
	 *
	 * @readonly
	 * @type {Message}
	 * @memberof Client
	 */
	public get message(): Message {
		return this.controllers[3] as Message;
	}

	/**
	 * Methods for working with the Pins object in Quebic.
	 *
	 * @readonly
	 * @type {Pins}
	 * @memberof Client
	 */
	public get pins(): Pins {
		return this.controllers[4] as Pins;
	}

	/**
	 * Methods for working with the Invite object in Quebic.
	 *
	 * @readonly
	 * @type {Invite}
	 * @memberof Client
	 */
	public get invite(): Invite {
		return this.controllers[5] as Invite;
	}

	/**
	 * Methods for working with gif's in Quebic.
	 *
	 * @readonly
	 * @type {Gif}
	 * @memberof Client
	 */
	public get gif(): Gif {
		return this.controllers[6] as Gif;
	}

	/**
	 * Methods for working with media in Quebic.
	 *
	 * @readonly
	 * @type {Media}
	 * @memberof Client
	 */
	public get media(): Media {
		return this.controllers[7] as Media;
	}

	/**
	 * Methods for working with the cdn in Quebic.
	 *
	 * @readonly
	 * @type {Cdn}
	 * @memberof Client
	 */
	public get cdn(): Cdn {
		return this.controllers[8] as Cdn;
	}

	/**
	 * Methods for working with private channels in Quebic.
	 *
	 * @readonly
	 * @type {Dm}
	 * @memberof Client
	 */
	public get dm(): Dm {
		return this.controllers[9] as Dm;
	}

	/**
	 * The current user session token.
	 *
	 * @readonly
	 * @type {(string | null)}
	 * @memberof Client
	 */
	public get sessionToken(): string | null {
		return this.token;
	}

	/**
	 * Sets a user session and connects to the Quebic Gateway with the specified session token.
	 *
	 * @param {QuebicToken} session
	 * @return {*}  {Promise<void>}
	 * @memberof Client
	 */
	public async login(session: QuebicToken): Promise<void> {
		// Set the token globally and auth the controllers.
		await this.loginWithoutGateway(session);

		if (this.gateway) {
			await this.gateway.destroy();
			this.gateway = null;
		}
		this.gateway = new GatewayManager(this.endpoints[1]);

		this.gateway.on(GatewayManagerEvents.Connected, () => this.emit(ClientEvents.Connected));
		this.gateway.on(GatewayManagerEvents.Connecting, () => this.emit(ClientEvents.Connecting));
		this.gateway.on(GatewayManagerEvents.Disconnected, () => this.emit(ClientEvents.Disconnected));
		this.gateway.on(GatewayManagerEvents.Message, (...args: any[]) => this.onMessage(args[0]));

		await this.gateway.connect(session.token);
	}

	/**
	 * Sets a user session for use with the API only. *WARNING:* This will not allow you to receive real time events and should only be used for
	 * debugging the API only without the Quebic Gateway.
	 *
	 * @param {QuebicToken} session
	 * @return {*}  {Promise<void>}
	 * @memberof Client
	 */
	public async loginWithoutGateway(session: QuebicToken): Promise<void> {
		// Prevent the user from providing a non-auth token
		if (session.token_type !== "auth") {
			throw new QuebicError(-1, "invalid_session_token_type");
		}

		this.token = session.token;
	}


	/**
	 * Destroys the user session and disconnects from the Quebic Gateway.
	 *
	 * @return {*}  {Promise<void>}
	 * @memberof Client
	 */
	public async destroy(): Promise<void> {
		await this.gateway?.destroy();

		this.token = "";
		this.gateway = null;
	}

	private onMessage(message: string) {
		try {
			const msg = Json2.parse(message) as GatewayMessage<any>;

			if (this.supportedOpcode(msg.o)) {
				this.opcodeCallbacks[msg.o].call(this, msg);
			} else {
				this.emit(ClientEvents.Error, new Error(`Server sent an unknown opcode: ${msg.o}`));
			}
		} catch (e) {
			this.emit(ClientEvents.Error, e as Error);
		}
	}

	private supportedOpcode(opcode: number): boolean {
		return opcode >= QuebicOpcodes.Hello && opcode <= QuebicOpcodes.Count;
	}

	private opcodeHello(msg: GatewayMessage<number>): void {
		this.gateway?.configureHeartbeat(msg.d);
		this.emit(ClientEvents.Ready);
	}

	private async opcodePushEvent(msg: GatewayMessage<string>) {
		this.emit(ClientEvents.PushEvent, new PushEventClass(this, msg) as PushEvent);
	}

	private async opcodeHeartbeat() {
		this.gateway?.send(new Heartbeat());
	}

	private async opcodeReconnect() {
		this.emit(ClientEvents.ReconnectRequired);
	}

	private async opcodeMemberList(msg: GatewayMessage<string>) {
		this.emit(ClientEvents.MemberListUpdate, new MemberListEvent(this, msg));
	}

	private async opcodeSearchMemberList(msg: GatewayMessage<string>) {
		this.emit(ClientEvents.SearchMemberList, new SearchMemberListEvent(this, msg));
	}

	private opcodeCount(msg: GatewayMessage<any>): void {
		throw new Error(`Failed to parse message: ${msg.o}`);
	}
}